"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { SvgIcon } from '../SvgIcon/index.mjs';
import { Truncate } from '../Truncate/index.mjs';
import clsx from 'clsx';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import { sva } from '../../styled-system/css/sva.mjs';
import { useDropdownItemContext } from './item-context.mjs';

function GenericSvgIcon({ type, ...props }) {
  return /* @__PURE__ */ jsx(SvgIcon, { type, ...props, "aria-hidden": "true" });
}
function GenericImgIcon({ src, ...props }) {
  return /* @__PURE__ */ jsx("img", { src, ...props, "aria-hidden": "true" });
}
const itemIcon = sva({
  className: "item_icon",
  slots: ["icon"],
  base: {
    icon: {
      display: "flex",
      flexShrink: "0",
      flexGrow: "0",
      w: "4",
      h: "4"
    }
  }
});
function ItemIcon({ className, ...props }) {
  const { icon } = itemIcon();
  if ("type" in props && props?.type) {
    return /* @__PURE__ */ jsx(GenericSvgIcon, { className: clsx(icon, className), ...props });
  }
  if ("src" in props && props?.src) {
    return /* @__PURE__ */ jsx(GenericImgIcon, { className: clsx(icon, className), ...props });
  }
  return null;
}
const itemTitle = sva({
  className: "item_title",
  slots: ["wrapper", "hint"],
  base: {
    wrapper: {
      display: "flex",
      flexGrow: "1",
      flexDir: "column",
      gap: "0.5",
      maxW: "full",
      overflowWrap: "break-word"
    },
    hint: {
      fontSize: "sm",
      fontStyle: "italic"
    }
  }
});
function ItemTitle({
  children,
  hint: hintNode
}) {
  const { id } = useDropdownItemContext();
  const { wrapper, hint } = itemTitle();
  return /* @__PURE__ */ jsxs("div", { className: wrapper, id, children: [
    /* @__PURE__ */ jsx(Truncate, { mode: "end", children }),
    hintNode && /* @__PURE__ */ jsx("small", { className: hint, children: hintNode })
  ] });
}

export { ItemIcon, ItemTitle };
