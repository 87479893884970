"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { DataBuilderFormulaType } from '@integration-app/sdk';
import { forwardRef } from 'react';
import { ComboBoxTag } from '../../ComboBoxElements/ComboBoxTag.mjs';
import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { Truncate } from '../../Truncate/index.mjs';
import useDataBuilder from '../data-builder-context.mjs';
import useDataBuilderField from '../field-context.mjs';
import { DataLinkTablesOptionsFactory } from '../../DataLinks/data-link-tables-option-factory.mjs';
import { useIntegrationAppClient } from '../../../contexts/integration-app-client-context.mjs';
import { useIntegrationAppConnection } from '../../../contexts/integration-app-connection-context.mjs';
import DataBuilderFormField from '../Field.mjs';
import { makeSubField } from '../data-field.mjs';

const FindAppRecordIdFormula = forwardRef(
  (_props, ref) => {
    return /* @__PURE__ */ jsx(ComboBoxTag, { ref, icon: SvgIconType.Array, variant: "lookup", children: /* @__PURE__ */ jsx(Truncate, { children: "Find App Record ID" }) });
  }
);
function FindAppRecordIdFormulaDropdown({
  formula,
  onChange
}) {
  const { field } = useDataBuilderField();
  const { client } = useIntegrationAppClient();
  const { connectionId } = useIntegrationAppConnection();
  const { isAdminMode } = useDataBuilder();
  if (formula?.type !== DataBuilderFormulaType.FIND_APP_RECORD_ID) {
    return /* @__PURE__ */ jsx("span", { children: "Unexpected value for drilldown. Something went wrong." });
  }
  const args = formula.args ?? {};
  const { dataLinkTable, recordId } = args;
  function handleChange(value) {
    onChange?.({
      $findAppRecordId: {
        ...args,
        ...value
      }
    });
  }
  function handleDataLinkTableKeyChange(value) {
    handleChange({
      dataLinkTable: {
        key: value
      }
    });
  }
  function handleRecordIdChange(value) {
    handleChange({
      recordId: value
    });
  }
  function makeDataLinkTableField() {
    return makeSubField(field, {
      valueLocator: `${field.valueLocator}.dataLinkTable.key`,
      value: dataLinkTable?.key,
      schema: { type: "string", title: "Data Link Table" },
      optionFactories: [
        new DataLinkTablesOptionsFactory({
          client,
          connectionId,
          /* FIXME: strictNullCheck temporary fix */
          // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
          isAdminMode
        })
      ],
      exampleVariables: null,
      variablesSchema: null,
      level: 1,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
      key: null,
      isInSchema: true
    });
  }
  function makeRecordIdField() {
    return makeSubField(field, {
      valueLocator: `${field.valueLocator}.$findAppRecordId.recordId`,
      schema: { type: "string", title: "External Record Id" },
      optionFactories: field.optionFactories,
      exampleVariables: field.exampleVariables,
      variablesSchema: field.variablesSchema,
      level: 1,
      /* FIXME: strictNullCheck temporary fix */
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
      key: null,
      value: recordId,
      isInSchema: true
    });
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      DataBuilderFormField,
      {
        field: makeDataLinkTableField(),
        onChange: handleDataLinkTableKeyChange
      }
    ),
    /* @__PURE__ */ jsx(DataBuilderFormField, { field: makeRecordIdField(), onChange: handleRecordIdChange })
  ] });
}

export { FindAppRecordIdFormulaDropdown, FindAppRecordIdFormula as default };
