"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { InlineEditable } from '../InlineEditable.mjs';
import { fieldLabel } from './data-field.mjs';
import { Title } from '../DataBuilderUI/index.mjs';
import useDataBuilder from './data-builder-context.mjs';
import { FieldDescription } from './FieldDescription.mjs';

function FieldName({
  field,
  onKeyChange,
  onEnter,
  onEsc,
  rightSlot,
  typeSelectorSlot
}) {
  const { errorFieldsLocators } = useDataBuilder();
  if (field.isInSchema || field.key === void 0) {
    const isErrorField = field?.valueLocator && (errorFieldsLocators || []).includes(field.valueLocator);
    return /* @__PURE__ */ jsx(
      Title,
      {
        required: field.isRequired,
        hasValue: !!field.value || field.value === false,
        hasError: !!isErrorField,
        typeSelectorSlot,
        rightSlot: rightSlot || /* @__PURE__ */ jsx(FieldDescription, { text: field.schema?.description }),
        children: fieldLabel(field)
      }
    );
  } else {
    return /* @__PURE__ */ jsx(
      Title,
      {
        typeSelectorSlot,
        rightSlot: rightSlot || /* @__PURE__ */ jsx(FieldDescription, { text: field.schema?.description }),
        children: /* @__PURE__ */ jsx(
          InlineEditable,
          {
            onChange: onKeyChange,
            onEnter,
            onEsc,
            autoFocus: !field.key,
            value: field.key,
            placeholder: "Enter field name"
          }
        )
      }
    );
  }
}

export { FieldName as default };
