"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import AwesomeDebouncePromise from '../_modules/awesome-debounce-promise.mjs';
import { useIntegrationApp } from '../contexts/integration-app-context.mjs';
import useSWR from 'swr';

const elementStateCache = /* @__PURE__ */ new Map();
function useElement(type, selector, accessorGenerator) {
  const integrationApp = useIntegrationApp();
  const elementKeyData = {
    type,
    token: integrationApp?.token,
    selector
  };
  const elementKey = JSON.stringify(elementKeyData);
  const elementState = elementStateCache.get(elementKey) ?? {
    updatedLocally: false,
    savingToServer: false,
    currentPutRequests: [],
    // Save current put requests to not update local state until all of them are done
    debouncedPut: AwesomeDebouncePromise(async (data) => {
      const requestId = Math.random();
      elementState.currentPutRequests.push(requestId);
      elementState.updatedLocally = false;
      elementState.savingToServer = true;
      try {
        const result = await accessor?.put(data);
        elementState.currentPutRequests = elementState.currentPutRequests.filter((id) => id !== requestId);
        if (!elementState.updatedLocally && elementState.currentPutRequests.length === 0) {
          elementState.savingToServer = false;
          await mutate(result, false);
        }
      } catch (e) {
        elementState.updatedLocally = true;
        throw e;
      } finally {
        elementState.savingToServer = false;
      }
    }, 500)
  };
  if (!elementStateCache.has(elementKey)) {
    elementStateCache.set(elementKey, elementState);
  }
  const accessor = integrationApp ? accessorGenerator(integrationApp) : void 0;
  const swrKey = accessor && selector ? `element:${elementKey}` : void 0;
  const {
    data: item,
    mutate,
    error,
    isLoading,
    isValidating
  } = useSWR(swrKey, () => accessor?.get(), {
    // pause revalidation if update is in progress to not overwrite local changes
    isPaused: () => elementState.updatedLocally || elementState.savingToServer
  });
  const loading = isLoading;
  const refreshing = isValidating;
  async function refresh() {
    return await mutate();
  }
  async function put(data) {
    if (!accessor?.put) {
      throw new Error(
        /* FIXME: strictNullCheck temporary fix */
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        `"put method is not supported for accessor ${accessor.constructor.name}`
      );
    }
    elementState.updatedLocally = true;
    const newLocalData = {
      ...item,
      ...data
    };
    await mutate(newLocalData, false);
    await elementState.debouncedPut(data);
  }
  async function patch(data) {
    const newData = {
      ...item,
      ...data
    };
    return put(newData);
  }
  async function archive() {
    if (!accessor?.archive) {
      return;
    }
    await mutate({ ...item, archivedAt: (/* @__PURE__ */ new Date()).toISOString() }, false);
    await accessor?.archive();
    await mutate();
  }
  async function create(data) {
    if (!accessor?.create) {
      throw new Error(
        `"create method is not supported for accessor ${accessor?.constructor.name}`
      );
    }
    const result = await accessor?.create(data);
    return await mutate(result);
  }
  return {
    accessor,
    item,
    loading,
    saving: elementState.updatedLocally || elementState.savingToServer,
    error,
    refresh,
    refreshing,
    create,
    patch,
    put,
    archive
  };
}

export { useElement };
