"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { forwardRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from '../Portal/index.mjs';
import classes from './Popover.module.css.mjs';
import debounce from '../../helpers/Debounce.mjs';
import { useWindowEvent } from '../../hooks/useWindowEvent.mjs';
import clsx from 'clsx';
import { getRefElement } from '../../helpers/getRefElement.mjs';
import '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../styled-system/jsx/box.mjs';
import { Root, Trigger, Positioner, Content } from '../../ui-kit/styled/popover.mjs';

function GenericPopover({
  refElement,
  isOpen = false,
  options = {},
  children,
  portalNode
}) {
  const [popperElement, setPopperElement] = useState();
  const safeRefElement = getRefElement(refElement);
  const {
    styles,
    attributes,
    update: updatePopover
  } = usePopper(safeRefElement, popperElement, options);
  const [debouncedResizeCallback] = debounce(() => {
    void updatePopover?.();
  }, 150);
  useWindowEvent("resize", debouncedResizeCallback);
  if (!isOpen) {
    return null;
  }
  return /* @__PURE__ */ jsx(Portal, { node: portalNode, children: /* @__PURE__ */ jsx(
    Box,
    {
      ref: setPopperElement,
      zIndex: "600",
      style: styles.popper,
      ...attributes.popper,
      children
    }
  ) });
}
const DefaultPopper = forwardRef(
  ({
    placement = "bottom",
    refElement,
    isOpen = false,
    offset = [0, 8],
    className,
    onClick,
    children,
    portalNode
  }, ref) => {
    const modifiers = [
      {
        name: "offset",
        options: {
          offset
        }
      }
    ];
    const popperJsOptions = {
      placement,
      modifiers
    };
    return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
      GenericPopover,
      {
        refElement,
        isOpen: !!refElement && isOpen,
        options: popperJsOptions,
        portalNode,
        children: /* @__PURE__ */ jsx(
          "div",
          {
            ref,
            className: clsx(classes.defaultPopover, className),
            onClick,
            children
          }
        )
      }
    ) });
  }
);
DefaultPopper.displayName = "DefaultPopper";
const PopoverNew = forwardRef(
  ({
    placement = "bottom-start",
    isOpen,
    offset = [0, 8],
    className,
    children,
    triggerNode
  }, ref) => {
    const [crossAxis, mainAxis] = offset;
    return /* @__PURE__ */ jsxs(
      Root,
      {
        open: isOpen,
        lazyMount: true,
        positioning: {
          placement,
          offset: {
            crossAxis,
            mainAxis
          }
        },
        children: [
          /* @__PURE__ */ jsx(Trigger, { asChild: true, children: triggerNode }),
          /* @__PURE__ */ jsx(Positioner, { ref, children: /* @__PURE__ */ jsx(
            Content,
            {
              bg: "neutral.2",
              borderWidth: "thin",
              borderColor: "neutral.5",
              px: "4",
              py: "2",
              rounded: "sm",
              minW: "28",
              shadow: "none",
              className,
              children
            }
          ) })
        ]
      }
    );
  }
);

export { DefaultPopper, PopoverNew };
