"use strict";
"use client";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { Popover } from '@ark-ui/react/popover';
export { PopoverContext as Context } from '@ark-ui/react/popover';
import '../../styled-system/helpers.mjs';
import '../../styled-system/css/conditions.mjs';
import '../../styled-system/css/css.mjs';
import { popover } from '../../styled-system/recipes/popover.mjs';
import { createStyleContext } from './utils/create-style-context.mjs';

const { withRootProvider, withContext } = createStyleContext(popover);
withRootProvider(Popover.RootProvider);
const Root = withRootProvider(Popover.Root);
withContext(Popover.Anchor, "anchor");
withContext(Popover.Arrow, "arrow");
withContext(Popover.ArrowTip, "arrowTip");
withContext(Popover.CloseTrigger, "closeTrigger");
const Content = withContext(Popover.Content, "content");
withContext(Popover.Description, "description");
withContext(Popover.Indicator, "indicator");
const Positioner = withContext(Popover.Positioner, "positioner");
withContext(Popover.Title, "title");
const Trigger = withContext(Popover.Trigger, "trigger");

export { Content, Positioner, Root, Trigger };
