"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { getFormula } from '@integration-app/sdk';
import { useState, useEffect } from 'react';
import { getFormulas } from '../../../../DataBuilder/Formula/index.mjs';
import { DropdownUI } from '../../../../DropdownUI/index.mjs';
import { useComboBox } from '../../../context/combobox-context.mjs';
import { Button } from '../../../../Button/Button.mjs';
import classes from './Formulas.module.css.mjs';
import { useKeyboardEventSubscribe, EVENT_TYPE } from '../../../../../hooks/keyboard-events.mjs';
import { useComboBoxDropdownContext } from '../../../context/combobox-dropdown-context.mjs';
import '../../../context/combobox-dropdown-search.mjs';
import '../../../context/combobox-dropdown-stack.mjs';

function DropdownPanelFormulaConfig({
  onOptionSelect
}) {
  const formulas = getFormulas();
  const { value, schema, variablesSchema } = useComboBox();
  const { drillUp } = useComboBoxDropdownContext();
  const [localValue, setLocalValue] = useState(value);
  useEffect(() => {
    setLocalValue(value);
  }, [JSON.stringify(value)]);
  useKeyboardEventSubscribe(EVENT_TYPE.OPTION_LEFT, () => {
    drillUp();
  });
  const formula = getFormula(localValue);
  const formulaSpec = formula?.type && formulas[formula?.type];
  function handleOnChange(newValue) {
    setLocalValue(newValue);
  }
  function handleOnSaveAndClose() {
    onOptionSelect(localValue);
  }
  if (!formulaSpec?.DropdownComponent) {
    return null;
  }
  const FormulaInDropdownPanelComponent = formulaSpec?.DropdownComponent;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DropdownUI.Panel, { className: classes.formulaConfig__panel, children: /* @__PURE__ */ jsx("div", { className: classes.formulaConfig__panelContent, children: /* @__PURE__ */ jsx(
      FormulaInDropdownPanelComponent,
      {
        formula,
        schema,
        variablesSchema,
        onChange: handleOnChange
      }
    ) }) }),
    /* @__PURE__ */ jsx(DropdownUI.Panel, { className: classes.formulaConfig__footer, children: /* @__PURE__ */ jsx(
      Button,
      {
        size: "small",
        variant: "secondary",
        onClick: handleOnSaveAndClose,
        children: "Save & Close"
      }
    ) })
  ] });
}

export { DropdownPanelFormulaConfig };
