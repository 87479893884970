"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

function isDefinedValue(value) {
  return value !== null && value !== void 0;
}

export { isDefinedValue };
