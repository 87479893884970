"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { DropdownUI } from '../../../../DropdownUI/index.mjs';
import { Notification } from '../../../../Notification/index.mjs';
import { PanelHeader } from './UiComponents.mjs';
import classes from './UiComponents.module.css.mjs';

function OptionsParametersRequired() {
  return /* @__PURE__ */ jsxs(DropdownUI.Panel, { withPaddings: true, className: classes.panel__options, children: [
    /* @__PURE__ */ jsx(PanelHeader, { children: "Available options" }),
    /* @__PURE__ */ jsx(Notification, { mode: "block", type: "default", size: "sm", children: "Please select parameters above to display lists" })
  ] });
}

export { OptionsParametersRequired };
