export const isProduction = process.env.NODE_ENV === 'production'

// Set this to true to enable custom error boundary in development mode
// and disable the default error boundary provided by React.
export const enableCustomErrorBoundaryInDevelopmentMode = false

export const enableCustomErrorBoundary =
  isProduction || enableCustomErrorBoundaryInDevelopmentMode

export const errorLevel = isProduction ? 'error' : 'debug'
