/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import styleInject from 'style-inject';

var css_248z = "\n  .Editor-module_editor__VVpoo p {\n    line-height: 2rem !important;\n    min-height: 2rem !important;\n    align-items: center !important;\n    margin: 0px !important;\n    padding: 0px !important;\n  }\n.Editor-module_editor__VVpoo .tiptap {\n    min-width: 7rem;\n  }\n.Editor-module_editor__VVpoo .tiptap:focus {\n    outline: 2px solid transparent;\n    outline-offset: 2px;\n}\n:is(.Editor-module_editor__VVpoo .tiptap) .is-editor-empty:first-child::before {\n    pointer-events: none;\n    float: left;\n    height: 0px;\n    --tw-text-opacity: 1;\n    color: rgb(170 170 170 / var(--tw-text-opacity));\n      content: attr(data-placeholder);\n}\n.Editor-module_editor__VVpoo .react-renderer {\n    display: contents;\n}\n";
var classes = {"editor":"Editor-module_editor__VVpoo"};
styleInject(css_248z);

export { classes as default };
