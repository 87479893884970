"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { createContext, useContext } from 'react';

const DropdownItemContext = createContext({});
const DropdownItemContextProvider = DropdownItemContext.Provider;
const useDropdownItemContext = () => {
  return useContext(DropdownItemContext);
};

export { DropdownItemContextProvider, useDropdownItemContext };
