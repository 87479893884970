"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { Extension } from '@tiptap/core';

const KeyboardShortcutHandler = ({
  onEnter
}) => {
  return Extension.create({
    name: "keyboardHandler",
    addKeyboardShortcuts() {
      return {
        "Shift-Enter": ({ editor }) => editor.commands.first(({ commands }) => [
          () => commands.newlineInCode(),
          () => commands.createParagraphNear(),
          () => commands.liftEmptyBlock(),
          () => commands.splitBlock()
        ]),
        Enter: (props) => {
          onEnter?.(props);
          return props.editor.commands.blur();
        }
      };
    }
  });
};

export { KeyboardShortcutHandler };
