/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".inputs-module_container__wDMh6 {\n  width: 100%;\n}\n@media (min-width: 640px) {\n  .inputs-module_container__wDMh6 {\n    max-width: 640px;\n  }\n}\n@media (min-width: 768px) {\n  .inputs-module_container__wDMh6 {\n    max-width: 768px;\n  }\n}\n@media (min-width: 1024px) {\n  .inputs-module_container__wDMh6 {\n    max-width: 1024px;\n  }\n}\n@media (min-width: 1280px) {\n  .inputs-module_container__wDMh6 {\n    max-width: 1280px;\n  }\n}\n@media (min-width: 1536px) {\n  .inputs-module_container__wDMh6 {\n    max-width: 1536px;\n  }\n}\n.inputs-module_fieldWrapper__fye1c {\n  width: -moz-fit-content;\n  width: fit-content;\n}\n.inputs-module_fieldWrapper__disabled__-VUne {\n  --tw-gradient-via-position: 95%;\n  background-image: linear-gradient(to right, var(--tw-gradient-stops));\n  --tw-gradient-from: #F8F8F8 var(--tw-gradient-from-position);\n  --tw-gradient-to: rgb(248 248 248 / 0) var(--tw-gradient-to-position);\n  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);\n  --tw-gradient-to: rgb(248 248 248 / 0)  var(--tw-gradient-to-position);\n  --tw-gradient-stops: var(--tw-gradient-from), #F8F8F8 var(--tw-gradient-via-position), var(--tw-gradient-to);\n  --tw-gradient-to: #fff var(--tw-gradient-to-position);\n}\n.inputs-module_fieldWrapper__clickable__FeG2H {\n  cursor: pointer;\n}\n.inputs-module_fieldWrapper__clickable__FeG2H:has(> * > .tiptap) {\n  cursor: text;\n}\n.inputs-module_fieldWrapper__required__o0Mxj {\n  /* there should be a style but I have no idea */\n}\n.inputs-module_tagWrapper__NIIH- {\n  display: contents;\n  cursor: pointer;\n\n  line-height: 1em;\n}\n.inputs-module_tagWrapper__readonly__1megS {\n  cursor: default;\n}\n.inputs-module_innerTag__HJFp9 {\n  display: inline-flex;\n  align-items: center;\n}\n";
var classes = {"container":"inputs-module_container__wDMh6","fieldWrapper":"inputs-module_fieldWrapper__fye1c","fieldWrapper__disabled":"inputs-module_fieldWrapper__disabled__-VUne","fieldWrapper__clickable":"inputs-module_fieldWrapper__clickable__FeG2H","fieldWrapper__required":"inputs-module_fieldWrapper__required__o0Mxj","tagWrapper":"inputs-module_tagWrapper__NIIH-","tagWrapper__readonly":"inputs-module_tagWrapper__readonly__1megS","innerTag":"inputs-module_innerTag__HJFp9"};
styleInject(css_248z);

export { classes as default };
