"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { NodeViewWrapper } from '@tiptap/react';
import { useCallback } from 'react';
import clsx from 'clsx';
import { ComboBox } from '../../ComboBox/ComboBox.mjs';
import { useComboBox } from '../../ComboBox/context/combobox-context.mjs';
import classes from '../inputs.module.css.mjs';
import useDataBuilder from '../../DataBuilder/data-builder-context.mjs';
import { usIsFieldFrozen } from '../../DataBuilder/hooks.mjs';

function Tag({
  value,
  autoOpen,
  onChange,
  disabled
}) {
  const { variablesSchema } = useComboBox();
  const handleValueChange = useCallback(
    (value2) => {
      onChange(value2);
    },
    [onChange]
  );
  return /* @__PURE__ */ jsx(
    ComboBox,
    {
      className: classes.innerTag,
      value,
      schema: { type: "string" },
      variablesOnly: true,
      disabled,
      variablesSchema,
      onChange: handleValueChange,
      autoOpen
    }
  );
}
const TagNodeView = (props) => {
  const { value, autoOpen } = props.node.attrs;
  const { isReadOnly } = useDataBuilder();
  const isFrozenField = usIsFieldFrozen();
  const handleChange = (value2) => {
    if (value2 === void 0) {
      props.deleteNode();
    } else {
      props.updateAttributes({
        value: value2
      });
    }
  };
  return /* @__PURE__ */ jsx(
    NodeViewWrapper,
    {
      as: "span",
      className: clsx(classes.tagWrapper, {
        [classes.tagWrapper__readonly]: isReadOnly || isFrozenField
      }),
      children: /* @__PURE__ */ jsx(
        Tag,
        {
          value,
          autoOpen,
          onChange: handleChange,
          disabled: isReadOnly || isFrozenField
        }
      )
    }
  );
};

export { TagNodeView };
