"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import '../context/combobox-context.mjs';
import { useComboBoxDropdownContext } from '../context/combobox-dropdown-context.mjs';
import '../context/combobox-dropdown-search.mjs';
import '../context/combobox-dropdown-stack.mjs';
import '@integration-app/sdk';
import { DropdownUI } from '../../DropdownUI/index.mjs';

const DrillUpButton = forwardRef(({ onClick, ...props }, ref) => {
  const { drillUp, drillDownStackTop } = useComboBoxDropdownContext();
  if (!drillDownStackTop) {
    return null;
  }
  return /* @__PURE__ */ jsx(
    DropdownUI.BackButton,
    {
      ref,
      onClick: (e) => {
        drillUp();
        onClick?.(e);
      },
      ...props,
      children: drillDownStackTop.label
    }
  );
});

export { DrillUpButton };
