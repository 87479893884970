"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { TAG_EXTENSION_NAME, TAG_EXTENSION_TAG } from './constants.mjs';
import { TagNodeView } from './TagNodeView.mjs';

const TagComponent = Node.create({
  name: TAG_EXTENSION_NAME,
  group: "inline",
  inline: true,
  atom: true,
  addAttributes() {
    return {
      id: {
        default: null
      },
      value: {
        default: null
      },
      autoOpen: {
        default: false
      }
    };
  },
  parseHTML() {
    return [
      {
        tag: TAG_EXTENSION_TAG
      }
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [TAG_EXTENSION_TAG, mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return ReactNodeViewRenderer(TagNodeView, { contentDOMElementTag: "span" });
  }
});

export { TagComponent };
