"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, useContext, useState } from 'react';
import { getFieldSchemaFromValue, getFieldSchemaOptions } from './data-field.mjs';

function useDataBuilderField() {
  return useContext(DataBuilderFieldContext);
}
const DataBuilderFieldContext = createContext(
  {
    field: { level: 0, optionFactories: [] },
    localSchema: {},
    setLocalSchema: () => {
    },
    resetLocalSchema: () => {
    },
    localSchemaChangedManually: false,
    setLocalSchemaChangedManually: () => {
    },
    autoOpen: false,
    setAutoOpen: () => {
    }
  }
);
DataBuilderFieldContext.displayName = "DataBuilderFieldContext";
const DataBuilderFieldProvider = ({ field, children }) => {
  const [localSchema, setLocalSchema] = useState(
    getDefaultLocalSchema(field)
  );
  function resetLocalSchema() {
    setLocalSchema(getDefaultLocalSchema(field));
  }
  const [localSchemaChangedManually, setLocalSchemaChangedManually] = useState(false);
  const [autoOpen, setAutoOpen] = useState(false);
  return /* @__PURE__ */ jsx(
    DataBuilderFieldContext.Provider,
    {
      value: {
        field,
        localSchema,
        setLocalSchema,
        resetLocalSchema,
        localSchemaChangedManually,
        setLocalSchemaChangedManually,
        autoOpen,
        setAutoOpen
      },
      children
    }
  );
};
function getDefaultLocalSchema(field) {
  const fieldSchemaFromValue = getFieldSchemaFromValue(field);
  const fieldSchemaOptions = getFieldSchemaOptions(field);
  if (field?.schema) {
    if (!field?.schema?.type) {
      return fieldSchemaFromValue || {
        ...field.schema,
        type: fieldSchemaOptions[0].type
      };
    }
    if (Array.isArray(field.schema.type)) {
      return fieldSchemaFromValue || {
        ...field.schema,
        type: field.schema.type[0]
      };
    }
    return field.schema;
  }
  return fieldSchemaFromValue || {
    type: fieldSchemaOptions[0].type
  };
}

export { DataBuilderFieldProvider, useDataBuilderField as default };
