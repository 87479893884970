"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { TAG_EXTENSION_NAME } from '../constants.mjs';

function isClickOnEditorCustomTagComponent(element, rootElement) {
  if (!element) {
    return false;
  }
  if (element === rootElement) {
    return false;
  }
  return isElementEditorCustomTagComponent(element) || isClickOnEditorCustomTagComponent(element?.parentElement, rootElement);
}
function isClickOnEditorComponent(element, rootElement) {
  if (!element) {
    return false;
  }
  if (element === rootElement) {
    return false;
  }
  return isElementEditorComponent(element) || isClickOnEditorComponent(element?.parentElement, rootElement);
}
function isElementEditorCustomTagComponent(element) {
  return element.classList.contains(`node-${TAG_EXTENSION_NAME}`);
}
function isElementEditorComponent(element) {
  return element.classList.contains("tiptap");
}

export { isClickOnEditorComponent, isClickOnEditorCustomTagComponent };
