"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { useRef, useLayoutEffect } from 'react';

function useWindowEvent(type, listener) {
  const listenerRef = useRef(listener);
  listenerRef.current = listener;
  useLayoutEffect(() => {
    function handler(event) {
      listenerRef.current.call(window, event);
    }
    window.addEventListener(type, handler);
    return () => window.removeEventListener(type, handler);
  }, [type]);
}

export { useWindowEvent };
