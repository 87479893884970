"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { createContext, forwardRef, useContext } from 'react';
import '../../../styled-system/css/css.mjs';
import { cx } from '../../../styled-system/css/cx.mjs';
import '../../../styled-system/helpers.mjs';
import { styled } from '../../../styled-system/jsx/factory.mjs';
import { isCssProperty } from '../../../styled-system/jsx/is-valid-prop.mjs';

const shouldForwardProp = (prop, variantKeys, options = {}) => options.forwardProps?.includes(prop) || !variantKeys.includes(prop) && !isCssProperty(prop);
const createStyleContext = (recipe) => {
  const StyleContext = createContext(null);
  const withRootProvider = (Component) => {
    const StyledComponent = (props) => {
      const [variantProps, otherProps] = recipe.splitVariantProps(props);
      const slotStyles = recipe(variantProps);
      return /* @__PURE__ */ jsx(StyleContext.Provider, { value: slotStyles, children: /* @__PURE__ */ jsx(Component, { ...otherProps }) });
    };
    return StyledComponent;
  };
  const withProvider = (Component, slot, options) => {
    const StyledComponent = styled(
      Component,
      {},
      {
        shouldForwardProp: (prop, variantKeys) => shouldForwardProp(prop, variantKeys, options)
      }
    );
    const StyledSlotProvider = forwardRef((props, ref) => {
      const [variantProps, otherProps] = recipe.splitVariantProps(props);
      const slotStyles = recipe(variantProps);
      return /* @__PURE__ */ jsx(StyleContext.Provider, { value: slotStyles, children: /* @__PURE__ */ jsx(
        StyledComponent,
        {
          ...otherProps,
          ref,
          className: cx(slotStyles?.[slot], props.className)
        }
      ) });
    });
    StyledSlotProvider.displayName = Component.displayName || Component.name;
    return StyledSlotProvider;
  };
  const withContext = (Component, slot) => {
    const StyledComponent = styled(Component);
    const StyledSlotComponent = forwardRef((props, ref) => {
      const slotStyles = useContext(StyleContext);
      return /* @__PURE__ */ jsx(
        StyledComponent,
        {
          ...props,
          ref,
          className: cx(slotStyles?.[slot], props.className)
        }
      );
    });
    StyledSlotComponent.displayName = Component.displayName || Component.name;
    return StyledSlotComponent;
  };
  return {
    withRootProvider,
    withProvider,
    withContext
  };
};

export { createStyleContext };
