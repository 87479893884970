import { ENV_CONFIG } from 'config/env'
import { createContext, useContext } from 'react'

interface TrackingContextInterface {
  addTrackingToUrl: (url: string) => string
}

const TrackingContext = createContext<TrackingContextInterface>(
  {} as TrackingContextInterface,
)

const LOCAL_STORAGE_KEY = 'trackingParams'
const TRACKING_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'gclid']

export function TrackingContextProvider({ children }) {
  if (typeof window !== 'undefined' && window.location.search) {
    const urlParams = new URLSearchParams(window.location.search)
    const trackingParams: Record<string, string> = {}
    for (const [key, value] of urlParams.entries()) {
      if (TRACKING_PARAMS.includes(key)) {
        trackingParams[key] = value
      }
    }
    if (Object.keys(trackingParams).length > 0) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(trackingParams))
    }
  }

  function addTrackingToUrl(url: string) {
    if (!ENV_CONFIG.ENABLE_TRACKING) return url
    if (typeof window === 'undefined') return url

    const [urlWithoutParams, urlParamsStr] = url.split('?')
    const urlParams = new URLSearchParams(urlParamsStr)
    const trackingParams = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY) ?? '{}',
    )
    for (const [key, value] of Object.entries<string>(trackingParams)) {
      urlParams.set(key, value)
    }
    const urlWithTracking = `${urlWithoutParams}?${urlParams.toString()}`
    return urlWithTracking
  }

  return (
    <TrackingContext.Provider value={{ addTrackingToUrl }}>
      {children}
    </TrackingContext.Provider>
  )
}

export function useTrackingContext() {
  return useContext(TrackingContext)
}
