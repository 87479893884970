/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".ParametersPanel-module_panel__sLw84 {\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    padding: 0.5rem\n}\n.ParametersPanel-module_panel__collapsed__8jnts {\n    --tw-bg-opacity: 1;\n    background-color: rgb(238 238 238 / var(--tw-bg-opacity))\n}\n.ParametersPanel-module_panel__expanded__u9DGn {\n    padding-bottom: 0.75rem\n}\n";
var classes = {"panel":"ParametersPanel-module_panel__sLw84","panel__collapsed":"ParametersPanel-module_panel__collapsed__8jnts","panel__expanded":"ParametersPanel-module_panel__expanded__u9DGn"};
styleInject(css_248z);

export { classes as default };
