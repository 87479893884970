"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { buildData } from '@integration-app/sdk';
import { extractFieldSubFields, isObjectAllowedAdditionalProperties } from './data-field.mjs';
import 'react/jsx-runtime';
import '../../ui-kit/styled/text.mjs';
import '../Tooltip/index.mjs';

function makeDataField({
  key = void 0,
  schema: inputSchema,
  value,
  optionFactories,
  variablesSchema,
  exampleVariables,
  iconUri,
  valueLocator,
  level = 1
}) {
  const schema = addAdditionalPropertiesPropertyToNestedObjects(
    buildData(inputSchema, buildData(value))
  );
  const field = {
    key,
    valueLocator: valueLocator ?? schema?.locator ?? "$",
    schema,
    level,
    isInSchema: !schema?.isImplied,
    hasSiblings: false,
    variablesSchema,
    exampleVariables,
    /* FIXME: strictNullCheck temporary fix */
    // @ts-expect-error TS(2322): Type 'ComboBoxOptionsFactory[] | undefined' is no... Remove this comment to see the full error message
    optionFactories,
    value,
    iconUri,
    dynamicSchema: inputSchema,
    dynamicSchemaVariables: value
  };
  field.subFields = extractFieldSubFields(field);
  return field;
}
function addAdditionalPropertiesPropertyToNestedObjects(schema) {
  if (schema?.type !== "object") {
    return schema;
  }
  const isAdditionalPropertiesAllowed = schema?.additionalProperties ?? isObjectAllowedAdditionalProperties({
    schema
  });
  const additionalPropertiesObject = isAdditionalPropertiesAllowed ? { additionalProperties: true } : {};
  return {
    ...schema,
    ...additionalPropertiesObject,
    // Iterate over all properties and calculate `additionalProperties` flag for them
    properties: Object.fromEntries(
      Object.entries(schema?.properties ?? {}).map(([k, v]) => [
        k,
        addAdditionalPropertiesPropertyToNestedObjects(v)
      ])
    )
  };
}

export { makeDataField };
