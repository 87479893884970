"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { schemaAllowsCustomValue, schemaIsScalar } from '@integration-app/sdk';

var ValueType = /* @__PURE__ */ ((ValueType2) => {
  ValueType2["STRING"] = "string";
  ValueType2["DATE"] = "date";
  ValueType2["DATETIME"] = "datetime";
  ValueType2["TIME"] = "time";
  ValueType2["NUMBER"] = "number";
  ValueType2["BOOLEAN"] = "boolean";
  ValueType2["ARRAY"] = "array";
  ValueType2["OBJECT"] = "object";
  ValueType2["ANY"] = "any";
  ValueType2["CUSTOM"] = "custom";
  return ValueType2;
})(ValueType || {});
function getValueSpecFromSchema(schema) {
  if (typeof schema?.type !== "string") {
    return { type: "any" /* ANY */, allowCustom: true };
  }
  switch (schema.type?.toLowerCase?.()) {
    case "string":
      if (schema.format?.toLowerCase() == "date") {
        return {
          type: "date" /* DATE */,
          allowCustom: true
        };
      }
      if (schema.format?.toLowerCase() == "date-time") {
        return {
          type: "datetime" /* DATETIME */,
          allowCustom: true
        };
      }
      if (schema.format?.toLowerCase() == "time") {
        return {
          type: "time" /* TIME */,
          allowCustom: true
        };
      } else {
        return {
          type: "string" /* STRING */,
          allowCustom: schemaAllowsCustomValue(schema)
        };
      }
    case "object":
      return { type: "object" /* OBJECT */ };
    case "array":
      return {
        type: "array" /* ARRAY */,
        allowCustom: true
      };
    case "number":
    case "integer":
      return {
        type: "number" /* NUMBER */,
        allowCustom: schemaAllowsCustomValue(schema)
      };
    case "boolean":
      return { type: "boolean" /* BOOLEAN */ };
    default:
      return { type: "any" /* ANY */, allowCustom: true };
  }
}
function doesSchemaMatchValueType(schema, valueSpec, deep = false) {
  if (!valueSpec?.type || valueSpec.type === "any" /* ANY */) {
    return true;
  }
  if (!schema?.type) {
    return true;
  }
  if (schema.type == "null") {
    return false;
  }
  if (schema.type == valueSpec.type) {
    return true;
  }
  if (schemaIsScalar(schema)) {
    if (valueSpec.type != "array" /* ARRAY */ && valueSpec.type != "object" /* OBJECT */) {
      return true;
    }
    return false;
  }
  if (deep) {
    if (schema.type === "object" && schema.properties) {
      return Object.values(schema.properties).some(
        (prop) => doesSchemaMatchValueType(prop, valueSpec, true)
      );
    }
    if (schema.type === "array" && schema.items) {
      return doesSchemaMatchValueType(schema.items, valueSpec, true);
    }
    return false;
  }
  return false;
}

export { ValueType, doesSchemaMatchValueType, getValueSpecFromSchema };
