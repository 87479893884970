"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { SvgIconType } from '../../SvgIcon/svg-icon-type.mjs';
import { DropdownCategoryType } from '../types.mjs';
import { OptionsCategoryContent } from './Categories/Options.mjs';
import { VariablesCategoryContent } from './Categories/Variables.mjs';
import { FormulasCategoryContent } from './Categories/Formulas/index.mjs';
import { CustomValueCategoryContent } from './Categories/CustomValue.mjs';
import { LookupCategoryContent } from './Categories/Lookup.mjs';
import { RootCategoryContent } from './Categories/Root.mjs';
import { DropdownPanelFormulaConfig } from './Categories/Formulas/DropdownPanelFormulaConfig.mjs';

const renderCategories = {
  [DropdownCategoryType.ROOT]: {
    title: "Root",
    hideSearchInput: false
  },
  [DropdownCategoryType.CUSTOM]: {
    title: "Custom Value",
    icon: SvgIconType.SchemaType_object,
    hideSearchInput: true
  },
  [DropdownCategoryType.VARIABLE]: {
    title: "Variables",
    icon: SvgIconType.Variable
  },
  [DropdownCategoryType.OPTIONS]: {
    title: "Option"
  },
  [DropdownCategoryType.FORMULA]: {
    title: "Formulas",
    icon: SvgIconType.Function,
    hideSearchInput: false
  },
  [DropdownCategoryType.FORMULA_CONFIG]: {
    title: "Formulas",
    icon: SvgIconType.Function,
    hideSearchInput: true
  },
  [DropdownCategoryType.LOOKUP_OPTIONS]: {
    title: "Lookup Options",
    hideSearchInput: false
  },
  [DropdownCategoryType.SELECT_FROM_UDM_DYNAMICALLY]: {
    title: "Select Dynamically",
    hideSearchInput: true,
    hasConfigUI: true,
    icon: SvgIconType.UDMEntity
  },
  [DropdownCategoryType.SELECT_FROM_COLLECTION_DYNAMICALLY]: {
    title: "Select Dynamically",
    hideSearchInput: true,
    hasConfigUI: true,
    icon: SvgIconType.UDMEntity
  }
};
const CATEGORY_COMPONENTS = {
  [DropdownCategoryType.ROOT]: RootCategoryContent,
  [DropdownCategoryType.OPTIONS]: OptionsCategoryContent,
  [DropdownCategoryType.VARIABLE]: VariablesCategoryContent,
  [DropdownCategoryType.FORMULA]: FormulasCategoryContent,
  [DropdownCategoryType.FORMULA_CONFIG]: DropdownPanelFormulaConfig,
  [DropdownCategoryType.CUSTOM]: CustomValueCategoryContent,
  [DropdownCategoryType.SELECT_FROM_UDM_DYNAMICALLY]: LookupCategoryContent,
  [DropdownCategoryType.SELECT_FROM_COLLECTION_DYNAMICALLY]: LookupCategoryContent,
  [DropdownCategoryType.LOOKUP_OPTIONS]: RootCategoryContent
};

export { CATEGORY_COMPONENTS, renderCategories };
