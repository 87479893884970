/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import styleInject from 'style-inject';

var css_248z = "@layer elements {\n  .Radio-module_uikit-radio__4iH4e {\n\n    position: relative;\n\n    display: inline-block;\n\n    -webkit-appearance: none;\n\n       -moz-appearance: none;\n\n            appearance: none;\n\n    align-self: baseline;\n\n    cursor: pointer;\n\n    border-width: 1px;\n\n    --tw-border-opacity: 1;\n\n    border-color: rgb(105 105 105 / var(--tw-border-opacity));\n\n    border-radius: 9999px;\n\n    --tw-bg-opacity: 1;\n\n    background-color: rgb(255 255 255 / var(--tw-bg-opacity));\n\n    margin: 0px;\n\n    margin-right: 0.375rem;\n\n    height: 1rem;\n\n    width: 1rem;\n\n    border-style: solid;\n  }\n\n  .Radio-module_uikit-radio__inline__CNV2H {\n\n    vertical-align: baseline;\n    transform: translateY(0.15rem);\n  }\n\n  .Radio-module_uikit-radio__checked__S22n6 {\n    background-image: url(\"data:image/svg+xml,%3Csvg viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='2.5'/%3E%3C/svg%3E\");\n    background-size: 100% 100%;\n    background-position: center center;\n    background-repeat: no-repeat;\n  }\n\n  .Radio-module_uikit-radio__4iH4e:active {\n\n    --tw-border-opacity: 1;\n\n    border-color: rgb(0 0 0 / var(--tw-border-opacity));\n  }\n  .Radio-module_uikit-radio__4iH4e:disabled {\n\n    --tw-border-opacity: 1;\n\n    border-color: rgb(170 170 170 / var(--tw-border-opacity));\n  }\n  .Radio-module_uikit-radio__4iH4e:disabled:checked {\n    background-image: url(\"data:image/svg+xml,%3Csvg viewBox='0 0 10 10' fill='#aaa' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='2.5'/%3E%3C/svg%3E\");\n  }\n  .Radio-module_uikit-radio__4iH4e:hover:not(:disabled, :active) {\n\n    --tw-border-opacity: 1;\n\n    border-color: rgb(0 0 0 / var(--tw-border-opacity));\n\n    --tw-bg-opacity: 1;\n\n    background-color: rgb(248 248 248 / var(--tw-bg-opacity));\n  }\n}\n";
var classes = {"uikit-radio":"Radio-module_uikit-radio__4iH4e","uikit-radio__inline":"Radio-module_uikit-radio__inline__CNV2H","uikit-radio__checked":"Radio-module_uikit-radio__checked__S22n6"};
styleInject(css_248z);

export { classes as default };
