"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import { toHeaderCase } from 'js-convert-case';
import { DropdownUI } from '../../../../DropdownUI/index.mjs';
import { Button } from '../../../../Button/Button.mjs';
import { makeDataField } from '../../../../DataBuilder/index.mjs';
import { DataBuilderForm } from '../../../../DataBuilder/Form.mjs';
import { SvgIcon } from '../../../../SvgIcon/index.mjs';
import { SvgIconType } from '../../../../SvgIcon/svg-icon-type.mjs';
import { PanelHeader, ParametersList, Parameter, ParameterText, PanelHeaderButton } from './UiComponents.mjs';
import clsx from 'clsx';
import classes from './ParametersPanel.module.css.mjs';

function ParametersPanel({ onChange, schema, value }) {
  const parametersProvided = isParametersProvided(value);
  const [isEditing, setIsEditing] = useState(!parametersProvided);
  function isParametersProvided(object) {
    return !!Object.keys(object).length;
  }
  function toggleIsEditing() {
    setIsEditing(!isEditing);
  }
  function handleOnClose() {
    setIsEditing(false);
  }
  return /* @__PURE__ */ jsxs(
    DropdownUI.Panel,
    {
      className: clsx(
        classes.panel,
        isEditing ? classes.panel__expanded : classes.panel__collapsed
      ),
      children: [
        /* @__PURE__ */ jsxs(PanelHeader, { children: [
          "Parameters",
          /* @__PURE__ */ jsx(EditParametersButton, { onClick: toggleIsEditing, isEditing })
        ] }),
        isEditing ? /* @__PURE__ */ jsx(
          ParametersPanelForm,
          {
            onChange,
            onClose: handleOnClose,
            schema,
            value
          }
        ) : /* @__PURE__ */ jsx(ParametersPanelView, { schema, value })
      ]
    }
  );
}
function ParametersPanelForm({ onClose, onChange, schema, value }) {
  const field = makeDataField({
    schema,
    value
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(DataBuilderForm, { field, onChange }),
    /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(Button, { onClick: onClose, variant: "secondary", children: "Save and Close" }) })
  ] });
}
function ParametersPanelView({
  schema,
  value
}) {
  const parameters = Object.entries(schema?.properties ?? {}).map(
    ([key, field]) => {
      return {
        title: field?.title || toHeaderCase(key),
        value: value?.[key]
      };
    }
  );
  if (!parameters.length) {
    return /* @__PURE__ */ jsx("p", { className: "text-neutral05", children: "No parameters provided" });
  }
  return /* @__PURE__ */ jsx(ParametersList, { children: parameters.map(({ title, value: value2 }, i) => /* @__PURE__ */ jsx(Parameter, { children: /* @__PURE__ */ jsx(ParameterText, { title, value: value2 }) }, i)) });
}
function EditParametersButton({
  onClick,
  isEditing
}) {
  const label = isEditing ? "Cancel" : "Edit";
  const icon = isEditing ? SvgIconType.Close : SvgIconType.Edit;
  return /* @__PURE__ */ jsxs(PanelHeaderButton, { onClick, children: [
    /* @__PURE__ */ jsx(SvgIcon, { type: icon }),
    " ",
    label
  ] });
}

export { ParametersPanel };
