"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

const TAG_EXTENSION_NAME = "tagComponent";
const TAG_EXTENSION_TAG = "tag-component";

export { TAG_EXTENSION_NAME, TAG_EXTENSION_TAG };
