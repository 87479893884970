"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { TbMoodSadFilled, TbMoodSmileFilled } from 'react-icons/tb';
import 'react';
import '../../../styled-system/css/css.mjs';
import '../../../styled-system/helpers.mjs';
import '../../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../../styled-system/jsx/box.mjs';
import { VisuallyHidden } from '../../../styled-system/jsx/visually-hidden.mjs';
import { Avatar } from '../../../ui-kit/avatar.mjs';
import { Icon } from '../../../ui-kit/styled/icon.mjs';

const IntegrationAvatarStatus = ({ integration, ...props }) => {
  if (!integration.connection) return null;
  const disconnected = integration.connection.disconnected;
  const StatusIcon = disconnected ? TbMoodSadFilled : TbMoodSmileFilled;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Icon,
      {
        position: "absolute",
        color: "white",
        top: "-1.5",
        right: "-1.5",
        fill: disconnected ? "tomato.11" : "green",
        bg: "white",
        borderRadius: "full",
        children: /* @__PURE__ */ jsx(StatusIcon, { "aria-hidden": "true", ...props })
      }
    ),
    /* @__PURE__ */ jsx(VisuallyHidden, { children: disconnected ? " - disconnected" : " - connected" })
  ] });
};
const IntegrationAvatar = ({ integration, ...props }) => {
  return /* @__PURE__ */ jsxs(Box, { position: "relative", ...props, children: [
    /* @__PURE__ */ jsx(
      Avatar,
      {
        src: integration.logoUri,
        name: integration.name,
        "aria-hidden": "true"
      }
    ),
    /* @__PURE__ */ jsx(IntegrationAvatarStatus, { integration })
  ] });
};

export { IntegrationAvatar };
