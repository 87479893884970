"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { useElements } from '../useElements.mjs';

function useIntegrations(query) {
  const { ...rest } = useElements("integrations", query);
  return {
    integrations: rest.items,
    ...rest
  };
}

export { useIntegrations };
