"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx, jsxs } from 'react/jsx-runtime';
import { forwardRef, useState, useId, useLayoutEffect } from 'react';
import clsx from 'clsx';
import { useIntersectionObserver } from 'usehooks-ts';
import { SvgIcon } from '../SvgIcon/index.mjs';
import { SvgIconType } from '../SvgIcon/svg-icon-type.mjs';
import '../Input/Input.mjs';
import { SearchInput } from '../Input/SearchInput.mjs';
import useEventStopPropagation from '../../hooks/useEventStopPropagation.mjs';
import { ItemIcon, ItemTitle } from './DropdownUIElements.mjs';
import * as DropdownUIElements from './DropdownUIElements.mjs';
export { DropdownUIElements };
import { useForwardedRef } from '../../hooks/useForwardedRef.mjs';
import { css } from '../../styled-system/css/css.mjs';
import '../../styled-system/helpers.mjs';
import { sva } from '../../styled-system/css/sva.mjs';
import '../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../styled-system/jsx/box.mjs';
import { Flex } from '../../styled-system/jsx/flex.mjs';
import { Text } from '../../ui-kit/styled/text.mjs';
import { IconButton } from '../../ui-kit/styled/icon-button.mjs';
import { useOptionListKeyNavDispatch } from '../../hooks/keyboard-events.mjs';
import { DropdownItemContextProvider } from './item-context.mjs';

const dropdownRootRecipe = sva({
  className: "dropdown",
  slots: ["root", "navigator"],
  base: {
    navigator: {
      outline: "none",
      display: "flex",
      overflow: "hidden",
      background: "white",
      boxShadow: "xl",
      rounded: "lg"
    },
    root: {
      display: "flex",
      flexDir: "column",
      p: "1.5",
      w: "full",
      minW: "56",
      maxW: "min(65em, 90vw)",
      maxH: "full"
    }
  }
});
const DropdownRoot = forwardRef(
  ({ children, className, ...props }, ref) => {
    const [keyboardRef, setKeyboardRef] = useState(null);
    const { root, navigator } = dropdownRootRecipe({});
    useOptionListKeyNavDispatch(keyboardRef);
    return /* @__PURE__ */ jsx(Box, { ref: setKeyboardRef, className: navigator, tabIndex: 0, children: /* @__PURE__ */ jsx(Flex, { ref, className: clsx(root, className), ...props, children }) });
  }
);
const DropdownSearch = forwardRef(
  ({ value, onChange, autoFocus, ...props }, ref) => {
    function handleSearchChange(event) {
      onChange?.(event.target.value);
    }
    const handleEventAndStopPropagation = useEventStopPropagation(true);
    return /* @__PURE__ */ jsx(
      DropdownPanel,
      {
        className: css({
          flexGrow: "0",
          flexShrink: "0",
          px: "2",
          py: "1.5"
        }),
        ...props,
        ref,
        children: /* @__PURE__ */ jsx(
          SearchInput,
          {
            variant: "border",
            className: css({ w: "full" }),
            placeholder: props.placeholder || "Search",
            value,
            autoFocus,
            onChange: handleSearchChange,
            onKeyUp: handleEventAndStopPropagation,
            onClick: handleEventAndStopPropagation
          }
        )
      }
    );
  }
);
const dropdownPanelRecipe = sva({
  className: "dropdown",
  slots: ["panel"],
  variants: {
    withPaddings: {
      true: {
        panel: {
          px: "4",
          py: "3"
        }
      }
    }
  },
  base: {
    panel: {
      display: "flex",
      flexDir: "column",
      // TODO: should not be hidden
      overflow: "hidden",
      "& + &": {
        borderTopWidth: "0.5",
        borderColor: "gray.3"
      },
      // TODO: check if this is still possible
      "& > &": {
        flexGrow: "0",
        flexShrink: "0"
      },
      // TODO: temporary styles
      rounded: "sm"
    }
  },
  defaultVariants: {
    withPaddings: false
  }
});
const DropdownPanel = forwardRef(
  ({ withPaddings = false, className, children, ...props }, ref) => {
    const { panel } = dropdownPanelRecipe({ withPaddings });
    return /* @__PURE__ */ jsx(Box, { className: clsx(panel, className), ref, ...props, children });
  }
);
const dropdownListRecipe = sva({
  className: "dropdown",
  slots: ["list"],
  base: {
    list: {
      overflowY: "auto",
      position: "relative",
      flexGrow: "1",
      // todo: is this valid?
      minH: "0"
    }
  }
});
const DropdownList = forwardRef(
  ({ children, className, ...props }, ref) => {
    const { list } = dropdownListRecipe({ className });
    return (
      // todo: this might have to be a UL
      /* @__PURE__ */ jsx(Box, { className: list, ...props, ref, children })
    );
  }
);
const DropdownItem = forwardRef(
  ({
    children,
    className,
    active = false,
    selected = false,
    readonly = false,
    hasError = false,
    isHighlighted = false,
    ...props
  }, ref) => {
    const id = useId();
    return /* @__PURE__ */ jsx(DropdownItemContextProvider, { value: { id }, children: /* @__PURE__ */ jsx(
      DropdownItemDiv,
      {
        ref,
        active,
        className: clsx(
          css({
            display: "flex",
            flexDir: "row",
            gap: "2",
            alignItems: "center",
            px: "4",
            py: "2.5",
            cursor: "pointer",
            minH: "12",
            borderBottomWidth: "0.5",
            borderColor: "gray.5",
            _last: {
              roundedBottom: "1",
              borderBottom: "0"
            },
            "&:is(&[aria-selected='true'],&[data-headlessui-state*='selected']):not(:hover,[aria-current],[data-headlessui-state*='active'])": {
              background: "gray.3",
              color: "black"
            },
            "&:hover,&[aria-current],&[data-headlessui-state*='active']": {
              background: "black",
              color: "white"
            },
            "&[aria-readonly]": {
              pointerEvents: "none",
              color: "gray.11"
            },
            _invalid: {
              pointerEvents: "none",
              background: "tomato.5"
            }
          }),
          isHighlighted && css({
            background: "gray.3"
          }),
          className
        ),
        "aria-selected": selected || void 0,
        "aria-current": active || void 0,
        "aria-readonly": readonly || void 0,
        "aria-invalid": hasError || void 0,
        role: "listitem",
        "aria-labelledby": id,
        ...props,
        children
      }
    ) });
  }
);
function DropdownLoadingPanel({ children }) {
  return /* @__PURE__ */ jsx(DropdownPanel, { children: /* @__PURE__ */ jsx(DropdownList, { children: /* @__PURE__ */ jsxs(DropdownItem, { readonly: true, children: [
    /* @__PURE__ */ jsx(ItemIcon, { type: SvgIconType.Loader }),
    /* @__PURE__ */ jsx(ItemTitle, { children })
  ] }) }) });
}
function DropdownErrorPanel({ children }) {
  return /* @__PURE__ */ jsx(DropdownPanel, { children: /* @__PURE__ */ jsx(DropdownList, { children: /* @__PURE__ */ jsxs(DropdownItem, { hasError: true, children: [
    /* @__PURE__ */ jsx(ItemIcon, { type: SvgIconType.AlertTriangle }),
    /* @__PURE__ */ jsx(ItemTitle, { children })
  ] }) }) });
}
const dropdownListHeaderRecipe = sva({
  className: "dropdown",
  slots: ["list-header"],
  base: {
    "list-header": {
      display: "flex",
      p: "2",
      color: "slate.11",
      textTransform: "uppercase",
      // TODO: might need to be even smaller
      textStyle: "xs",
      fontWeight: "medium",
      letterSpacing: "wide",
      position: "sticky",
      top: "0",
      zIndex: "docked",
      minH: "auto",
      pointerEvents: "none",
      // TODO: temporary styles
      background: "white",
      px: "2",
      py: "4",
      borderBottomWidth: "0.5",
      borderColor: "gray.5"
    }
  }
});
function DropdownListHeader({
  children,
  className,
  active,
  ...props
}) {
  const styles = dropdownListHeaderRecipe({ className });
  const listHeader = styles["list-header"];
  const id = useId();
  return /* @__PURE__ */ jsx(DropdownItemContextProvider, { value: { id }, children: /* @__PURE__ */ jsx(
    Text,
    {
      as: "span",
      role: "listitem",
      className: listHeader,
      "aria-current": active || void 0,
      "aria-labelledby": id,
      ...props,
      children
    }
  ) });
}
const dropdownBackButtonRecipe = sva({
  className: "back-button",
  slots: ["container", "button", "icon", "title"],
  base: {
    container: {
      gap: "2",
      alignItems: "center",
      px: "1.5",
      py: "1"
    },
    button: {
      h: "6",
      w: "6",
      minW: "unset",
      rounded: "md",
      color: "slate.11"
    },
    icon: {
      w: "4",
      h: "4"
    },
    title: { fontWeight: "medium", color: "slate.11", fontSize: "sm" }
  }
});
const DropdownBackButton = forwardRef(({ className, children, ...props }, ref) => {
  const { container, button, icon, title } = dropdownBackButtonRecipe({
    className
  });
  const id = useId();
  return /* @__PURE__ */ jsxs(Flex, { className: container, children: [
    /* @__PURE__ */ jsx(
      IconButton,
      {
        ref,
        variant: "ghost",
        colorPalette: "gray",
        className: button,
        "aria-labelledby": id,
        ...props,
        children: /* @__PURE__ */ jsx(SvgIcon, { type: SvgIconType.ChevronLeft, className: icon })
      }
    ),
    /* @__PURE__ */ jsx(Text, { as: "span", className: title, id, children })
  ] });
});
const DropdownUI = Object.assign(DropdownRoot, {
  Search: DropdownSearch,
  Panel: DropdownPanel,
  List: DropdownList,
  Item: DropdownItem,
  HeaderItem: DropdownListHeader,
  BackButton: DropdownBackButton,
  LoadingPanel: DropdownLoadingPanel,
  ErrorPanel: DropdownErrorPanel
});
const DropdownItemDiv = forwardRef(
  ({ children, active, ...props }, ref) => {
    if (active) {
      return /* @__PURE__ */ jsx(ScrollIntoViewDiv, { ...props, children });
    }
    return /* @__PURE__ */ jsx("div", { ref, ...props, children });
  }
);
const ScrollIntoViewDiv = forwardRef(({ children, ...props }, ref) => {
  const actualRef = useForwardedRef(ref);
  useScrollIntoViewOnce(actualRef);
  return /* @__PURE__ */ jsx("div", { ...props, ref: actualRef, children });
});
function useScrollIntoViewOnce(ref) {
  const threshold = 0.7;
  const freezeOnceVisible = true;
  const entry = useIntersectionObserver(ref, {
    threshold,
    freezeOnceVisible
  });
  useLayoutEffect(() => {
    if (!entry) {
      return;
    }
    if (
      // Hack for TS
      // IntersectionObserverEntry.isVisible is not in the type definition
      entry["isVisible"] || entry.isIntersecting && entry.intersectionRatio > 0.7
    ) {
      return;
    }
    entry.target.scrollIntoView({
      block: "nearest"
    });
  }, [entry]);
}

export { DropdownUI, ItemTitle };
