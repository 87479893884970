"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { DropdownUI } from '../../../DropdownUI/index.mjs';
import usePromise from '../../../../_modules/react-use-promise.mjs';
import { getReferenceCollectionPathForSchema, schemaWithTitle, pickFieldsFromSchema, getDataCollectionCreateFields, UNIFIED_DATA_MODELS } from '@integration-app/sdk';
import { Radiobutton } from '../../../Radio/index.mjs';
import { useIntegrationAppClient } from '../../../../contexts/integration-app-client-context.mjs';
import { useIntegrationAppConnection } from '../../../../contexts/integration-app-connection-context.mjs';
import { useIntegrationAppIntegration } from '../../../../contexts/integration-app-integration-context.mjs';
import 'react';
import '../../../../styled-system/css/css.mjs';
import '../../../../styled-system/helpers.mjs';
import '../../../../styled-system/jsx/is-valid-prop.mjs';
import { Box } from '../../../../styled-system/jsx/box.mjs';
import { makeSubField } from '../../../DataBuilder/data-field.mjs';
import useDataBuilderField from '../../../DataBuilder/field-context.mjs';
import DataBuilderFormField from '../../../DataBuilder/Field.mjs';
import { makeDataField } from '../../../DataBuilder/index.mjs';
import { DataBuilderForm } from '../../../DataBuilder/Form.mjs';
import classes from './Collections.module.css.mjs';
import { useKeyboardEventSubscribe, EVENT_TYPE } from '../../../../hooks/keyboard-events.mjs';
import '../../context/combobox-context.mjs';
import { useComboBoxDropdownContext } from '../../context/combobox-dropdown-context.mjs';
import '../../context/combobox-dropdown-search.mjs';
import '../../context/combobox-dropdown-stack.mjs';
import { getLookupCollection } from './getLookupCollection.mjs';

function LookupCategoryContent({
  onOptionSelect
}) {
  const { field } = useDataBuilderField();
  if (!field) {
    return null;
  }
  function handleSelect(value) {
    onOptionSelect?.(value);
  }
  return /* @__PURE__ */ jsx(DataBuilderDrillDownLookup, { field, onChange: handleSelect });
}
function DataBuilderDrillDownLookup({ field, onChange }) {
  const { client } = useIntegrationAppClient();
  const { integrationId } = useIntegrationAppIntegration();
  const { connectionId } = useIntegrationAppConnection();
  const { drillUp } = useComboBoxDropdownContext();
  const lookupValue = field.value?.$lookup ?? {};
  const [
    { queryFieldsSchema, createFieldsSchema } = {
      queryFieldsSchema: null,
      createFieldsSchema: null
    },
    error,
    state
  ] = usePromise(
    () => getLookupSchemas({ field, client, connectionId, integrationId }),
    [getReferenceCollectionPathForSchema(field.schema)]
  );
  useKeyboardEventSubscribe(EVENT_TYPE.OPTION_LEFT, () => {
    drillUp();
  });
  function handleLookupChange(value) {
    onChange?.({
      $lookup: {
        ...lookupValue,
        ...value
      }
    });
  }
  function handleNewRecordChange(value) {
    onChange?.({
      $lookup: {
        ...lookupValue,
        create: {
          fields: value
        }
      }
    });
  }
  function handleLeaveEmptyClicked() {
    handleLookupChange({
      createIfNotFound: false
    });
  }
  function handleCreateNewClicked() {
    handleLookupChange({
      createIfNotFound: true
    });
  }
  function makeNewRecordField() {
    return makeSubField(field, {
      valueLocator: `${field.valueLocator}.$lookup.create.fields`,
      schema: schemaWithTitle(createFieldsSchema, "Fields to Create"),
      isInSchema: true,
      value: lookupValue.create?.fields,
      iconUri: field.iconUri,
      level: 1
    });
  }
  if (error) {
    return /* @__PURE__ */ jsxs("p", { children: [
      "Error: ",
      error.toString()
    ] });
  }
  if (state === "pending") {
    return /* @__PURE__ */ jsx("p", { children: "Loading..." });
  }
  if (!queryFieldsSchema) {
    return /* @__PURE__ */ jsx("p", { children: "This collection does not support lookup." });
  }
  const dataBuilderField = makeDataField({
    value: lookupValue.query,
    schema: schemaWithTitle(queryFieldsSchema, "Query fields"),
    variablesSchema: field.variablesSchema
  });
  return /* @__PURE__ */ jsx(Box, { maxH: "full", overflow: "scroll", children: /* @__PURE__ */ jsxs(DropdownUI.Panel, { children: [
    /* @__PURE__ */ jsx(DropdownUI.Panel, { withPaddings: true, children: /* @__PURE__ */ jsx(
      DataBuilderForm,
      {
        showNullValueFields: true,
        field: dataBuilderField,
        onChange: (query) => handleLookupChange({ query })
      }
    ) }),
    createFieldsSchema && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(
        DropdownUI.Panel,
        {
          withPaddings: true,
          className: classes.createFieldSchemaForm,
          children: [
            /* @__PURE__ */ jsx("strong", { children: "If nothing found" }),
            /* @__PURE__ */ jsxs("label", { children: [
              /* @__PURE__ */ jsx(
                Radiobutton,
                {
                  checked: !lookupValue.createIfNotFound,
                  onChange: () => handleLeaveEmptyClicked(),
                  inline: true
                }
              ),
              " ",
              "Leave empty"
            ] }),
            /* @__PURE__ */ jsxs("label", { children: [
              /* @__PURE__ */ jsx(
                Radiobutton,
                {
                  checked: !!lookupValue.createIfNotFound,
                  onChange: () => handleCreateNewClicked(),
                  inline: true
                }
              ),
              " ",
              "Create new"
            ] })
          ]
        }
      ),
      lookupValue.createIfNotFound && /* @__PURE__ */ jsx(
        DropdownUI.Panel,
        {
          withPaddings: true,
          className: classes.createFieldSchema,
          children: /* @__PURE__ */ jsx(
            DataBuilderFormField,
            {
              field: makeNewRecordField(),
              onChange: handleNewRecordChange
            }
          )
        }
      )
    ] })
  ] }) });
}
async function getLookupSchemas({
  field,
  client,
  connectionId,
  integrationId
}) {
  const referencePath = getReferenceCollectionPathForSchema(field?.schema);
  const referenceUdm = field.schema?.referenceUdm;
  if (referencePath && field.schema) {
    const collection = await getLookupCollection({
      schema: field.schema,
      client,
      connectionId,
      integrationId
    });
    if (!collection) {
      return {};
    }
    const queryFieldsSchema = pickFieldsFromSchema(
      collection?.fieldsSchema,
      collection?.match?.fields ?? collection?.find?.queryFields
    );
    delete queryFieldsSchema.required;
    const createFieldsSchema = getDataCollectionCreateFields(collection);
    return {
      queryFieldsSchema,
      createFieldsSchema
    };
  } else if (referenceUdm) {
    const udmSpec = UNIFIED_DATA_MODELS[referenceUdm];
    if (udmSpec) {
      return {
        queryFieldsSchema: udmSpec.match?.fields ? pickFieldsFromSchema(udmSpec.fieldsSchema, udmSpec.match.fields) : void 0,
        createFieldsSchema: udmSpec.create?.fields ? pickFieldsFromSchema(udmSpec.fieldsSchema, udmSpec.create.fields) : udmSpec.fieldsSchema
      };
    } else {
      return {};
    }
  } else {
    return {};
  }
}

export { LookupCategoryContent };
