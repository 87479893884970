"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsxs, jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import { Root, Trigger, Positioner, Content, Arrow, ArrowTip } from '../../ui-kit/styled/tooltip.mjs';
import { token } from '../../styled-system/tokens/index.mjs';

const Tooltip = forwardRef(
  ({
    tooltip,
    placement = "right",
    delayToShow = 300,
    delayToHide = 50,
    offset = [0, 6],
    className,
    children,
    disabled
  }, ref) => {
    const [crossAxis, mainAxis] = offset;
    return /* @__PURE__ */ jsxs(
      Root,
      {
        lazyMount: true,
        openDelay: delayToShow,
        closeDelay: delayToHide,
        positioning: {
          offset: {
            mainAxis,
            crossAxis
          },
          placement
        },
        disabled,
        children: [
          /* @__PURE__ */ jsx(Trigger, { asChild: true, children }),
          /* @__PURE__ */ jsx(Positioner, { className, children: /* @__PURE__ */ jsxs(
            Content,
            {
              background: "neutral.3",
              rounded: "sm",
              color: "black",
              shadow: "none",
              fontWeight: "normal",
              ref,
              children: [
                /* @__PURE__ */ jsx(
                  Arrow,
                  {
                    style: {
                      // https://github.com/cschroeter/park-ui/issues/411
                      // @ts-ignore
                      "--arrow-background": token(`colors.neutral.3`),
                      "--arrow-size": token(`spacing.2`)
                    },
                    children: /* @__PURE__ */ jsx(ArrowTip, {})
                  }
                ),
                tooltip
              ]
            }
          ) })
        ]
      }
    );
  }
);

export { Tooltip };
