/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import styleInject from 'style-inject';

var css_248z = ".Formulas-module_formulaConfig__panel__AUxse {\n  padding: 1rem;\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem\n}\n.Formulas-module_formulaConfig__panelContent__RHSfh {\n  min-height: 0px;\n  flex: 1 1 0%;\n  overflow-x: auto;\n  width: min(30rem, 70vw)\n}\n.Formulas-module_formulaConfig__footer__h3xmQ {\n  flex-shrink: 0;\n  flex-direction: row;\n  align-items: flex-start;\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n  padding-left: 1rem;\n  padding-right: 0.5rem\n}\n";
var classes = {"formulaConfig__panel":"Formulas-module_formulaConfig__panel__AUxse","formulaConfig__panelContent":"Formulas-module_formulaConfig__panelContent__RHSfh","formulaConfig__footer":"Formulas-module_formulaConfig__footer__h3xmQ"};
styleInject(css_248z);

export { classes as default };
