"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

class BooleanOptionsFactory {
  async getOptions({}) {
    return this.getOptionsSync();
  }
  getOptionsSync() {
    return [
      {
        label: "Yes",
        value: true
      },
      {
        label: "No",
        value: false
      }
    ];
  }
}

export { BooleanOptionsFactory };
