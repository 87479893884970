"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { forwardRef } from 'react';
import clsx from 'clsx';
import { ComboBoxTag } from '../../ComboBoxElements/ComboBoxTag.mjs';
import { Truncate } from '../../Truncate/index.mjs';
import classes from './styles.module.css.mjs';

const RecordFormula = forwardRef(
  (props, ref) => {
    const { formula } = props;
    const name = formula.record.name ?? formula.record?.id;
    return /* @__PURE__ */ jsx(ComboBoxTag, { ref, disabled: props.disabled, children: /* @__PURE__ */ jsx(
      "span",
      {
        className: clsx(classes.valueRef, classes.valueRefFlex),
        title: name,
        children: /* @__PURE__ */ jsx(Truncate, { children: name })
      }
    ) });
  }
);
RecordFormula.displayName = "RecordFormula";

export { RecordFormula as default };
