import classes from './ErrorToast.module.css'
import ErrorMessage from './ErrorMessage'

function ErrorToastRoot({ children }) {
  return <ErrorMessage className={classes.wrapper}>{children}</ErrorMessage>
}

const ErrorToast = Object.assign(ErrorToastRoot, {
  Header: ErrorMessage.Header,
  Content: ErrorMessage.Content,
})
export default ErrorToast
