"use strict";
/*
 * Integration App React package
 * {@link https://www.npmjs.com/package/@integration-app/react}
 * v2.0.3-beta.3
 */
'use client';

import { jsx } from 'react/jsx-runtime';
import { IntegrationAppClient } from '@integration-app/sdk';
import { createContext, useMemo, useContext } from 'react';

const IntegrationAppContext = createContext(null);
IntegrationAppContext.displayName = "IntegrationAppClientContext";
const IntegrationAppProvider = ({
  token,
  fetchToken,
  credentials,
  fetchCredentials,
  /* FIXME: strictNullCheck temporary fix */
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  apiUri = null,
  /* FIXME: strictNullCheck temporary fix */
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  uiUri = null,
  children
}) => {
  const client = useMemo(
    () => new IntegrationAppClient({
      token,
      fetchToken,
      credentials,
      fetchCredentials,
      apiUri,
      uiUri
    }),
    [token, JSON.stringify(credentials), apiUri, uiUri]
  );
  return /* @__PURE__ */ jsx(IntegrationAppContext.Provider, { value: client, children });
};
function useIntegrationApp() {
  return useContext(IntegrationAppContext);
}

export { IntegrationAppProvider, useIntegrationApp };
