import Axios from 'axios'
import { ENV_CONFIG } from 'config/env'

export default function useApi() {
  const apiClient = getClient()

  return {
    apiClient,
    apiFetcher: fetcher,
    getToken,
    setToken,
    unsetToken,
    getImpersonationToken,
    setImpersonationToken,
    unsetImpersonationToken,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetcher(url: string): Promise<any> {
  const response = await getClient().get(url)
  return response.data
}

function getClient() {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  }

  return Axios.create({
    baseURL: ENV_CONFIG.ENGINE_API_URI,
    headers,
  })
}

function getToken() {
  if (typeof window !== 'undefined') {
    return getImpersonationToken() ?? localStorage.getItem('jwt')
  }
  return null
}

function setToken(token) {
  if (typeof window !== 'undefined') {
    localStorage.setItem('jwt', token)
  }
}

function unsetToken() {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('jwt')
  }
}

function getImpersonationToken() {
  if (
    typeof window !== 'undefined' &&
    localStorage.getItem('impersonationToken')
  ) {
    return localStorage.getItem('impersonationToken')
  }
  return null
}

function setImpersonationToken(token) {
  if (typeof window !== 'undefined' && token) {
    localStorage.setItem('impersonationToken', token)
  }
}

function unsetImpersonationToken() {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('impersonationToken')
  }
}
